import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid, //Stack, IconButton, Box, styled, 
} from "@mui/material";
import { grey } from "@mui/material/colors";
//import ThumbUpIcon from "@mui/icons-material/ThumbUp";
//import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
//import ShareIcon from "@mui/icons-material/Share";
//import FavoriteIcon from "@mui/icons-material/Favorite";
//import ScreenRotationAltIcon from "@mui/icons-material/ScreenRotationAlt";
import { PointsBreakupCard } from 'sections';
import { Typography } from 'core';
import { fGetPostEngPoints } from 'lib/appFx';
import { selectAuthUserId } from 'services/auth/authSlice';
//import { DEF_ENG_POINT } from "config/app.config";
//import { getFeedListMetaData } from 'services/orgFeedApi';
//---------------------------------------------------------

/*function PostSelfCommands({
  pInff,
  srcKey,
  postId,
  setMessage,
  onCommClick,
  handleSocialEngagementActions,
  fFeedAct,
  hasUserLiked,
  feedData,
  setHasUserLiked,
}) {
  const feedListMeta = useSelector(getFeedListMetaData); //, shallowEqual);

  let likeIcon, shareIcon;
  if (srcKey === 'tw') {
    likeIcon = (
      <FavoriteIcon
        sx={{
          color: (theme) => (hasUserLiked ? theme.palette.secondary.dark : ''),
        }}
      />
    );
    // shareIcon =
    // 	<ScreenRotationAltIcon
    // 		sx={{
    // 			color: (theme) => theme.palette.secondary.dark,
    // 			transform: "rotate(135deg)",
    // 			fontSize: "1.7rem",
    // 		}}
    // 	/>;
  } else {
    likeIcon = (
      <ThumbUpIcon
        sx={{
          color: (theme) => (hasUserLiked ? theme.palette.secondary.dark : ''),
        }}
      />
    );
    shareIcon = <ShareIcon sx={{ color: grey[400] }} />;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => {
            fFeedAct(
              {
                actType: 'like',
                args: 'like',
                destSMKey: srcKey,
                feedData,
                postId,
                snetworkplatform_id: feedData.snetworkplatform_id,
                feedListMeta,
                pUrl: pInff.pUrl,
              },
              () => {
                setHasUserLiked(!hasUserLiked);
              },
              (err) => {
                setMessage(err);
              }
            );
          }}
        >
          {likeIcon}
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={onCommClick}
          xid={'btnComment_' + srcKey + '_' + postId}
        >
          <ChatBubbleIcon sx={{ color: grey[400] }} />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => fFeedAct({ action: 'share', destSMKey: srcKey })}
        >
          {shareIcon}
        </IconButton>
      </Stack>
    </Stack>
  );
}*/
//---------------------------------------------------------

export default function PostCommandBarSelf({
  pInff,
  theme,
  feedListMeta,
  postCustomData: pInf,
  feedData,
  //fFeedActionHandler,
  defEngPoints,
  setMessage,
}) {
  //console.log('feedListMetaPt', feedListMeta);
  const user_id = useSelector(selectAuthUserId);
  //getSMediaIcons(pInf.srcKey, 'initCap') || null
  const commentBoxId = 'txComment_' + pInf.srcKey + '_' + pInf.postId;
  const [showPointsBreakupFeedForm, setShowPointsBreakupForm] = useState(false);
  const [hasUserLiked, setHasUserLiked] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const engPts = pInf.engPoints;
  //console.log('pInfPCBS '+ pInf.postId, pInf);

  useEffect(() => {
    if (feedData && pInff) {
      const sSK = pInff.srcKey;
      const pEngPtsInf = fGetPostEngPoints(sSK, pInff?.engPoints);
      let ePtsGT = 0;

      pEngPtsInf && Object.keys(pEngPtsInf[sSK]).forEach(dSK => {
        ePtsGT += pEngPtsInf[sSK][dSK]['engPoints'];//|| DEF_ENG_POINT
      });
      /*let i = 0;
      //feedData.points?.map((data) => { i += data.points;});
      Object.keys(engPts).forEach((fPts) => {
        i += engPts[fPts]['share'];
      });*/
      setTotalPoints(ePtsGT);

      const UserLiked = feedData?.engagements.user?.some(
        (userData) => userData.id === user_id && userData?.type === 'like'
      );
      setHasUserLiked(UserLiked);
    }
  }, [pInff, feedData, feedListMeta]);
  function handlePointsBreakupFeedForm() {
    setShowPointsBreakupForm((prev) => !prev);
  }

  function handleCommentInputFocus() {
    const txCommentEl = document.getElementById(commentBoxId);
    // txCommentEl.focus();
  }

  //---------------------------------------------------------

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderTop: `3px solid ${grey[200]}` }}
      >
        <Grid item /*spacing={4}*/>
          {/* <PostSelfCommands
              pInff={pInff}
              setMessage={setMessage}
              setHasUserLiked={setHasUserLiked}
              feedData={feedData}
              hasUserLiked={hasUserLiked}
              fFeedAct={fFeedActionHandler}
              onCommClick={handleCommentInputFocus}
              srcKey={pInf.srcKey}
              postId={pInf.postId}
            /> */}
        </Grid>
        {<Grid item mt={1} mb={1}>
          <Typography
            color_variant="dark"
            size="small"
            component={'a'}
            onClick={handlePointsBreakupFeedForm}
            sx={{ textDecoration: 'none', padding: '5px', cursor: 'pointer' }}
          >
            Total Coin(s): {totalPoints}
          </Typography>
        </Grid>}
      </Grid>

      <PointsBreakupCard
        //points={feedData.points}
        theme={theme}
        pInff={pInff}
        //engPoints={engPts}
        showPointsBreakupFeedForm={showPointsBreakupFeedForm}
        onClosePointsBreakupFeedForm={handlePointsBreakupFeedForm}
      />
    </>
  );
}
