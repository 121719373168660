import { Avatar, Box, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Button, Typography } from "core";
import React from "react";
import { Link } from "react-router-dom";

const StyledAnalyticsCard = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  flexGrow: "1",
  justifyContent: "center",
  backgroundColor: theme.palette.background.paper,
  padding: "1rem",
  height: "190px",
  gap: "4rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

const StyledAnalyticsHeader = styled(Stack)(({ theme, highlight }) => ({
  padding: "0.5rem",
  backgroundColor: "#dce4e7",
  borderBottom: `2px solid ${grey[400]}`,
}));

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  border: "2px solid grey",
  boxShadow: "none",
  fontSize: "13px",
  maxWidth: 130,
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center",
  padding: "0",
  textTransform: "capitalize",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

const scorers = [
  {
    profile: "/assets/images/profile.png",
    name: "Tony ",
    points: 280,
    button: "Yesterday",
  },
  {
    profile: "/assets/user/courtney.avif",
    name: "Courtney",
    points: 280,
    button: "This Week",
  },
  {
    profile: "/assets/user/alexander.avif",
    name: "Alexander",
    points: 280,
    button: "Last Week",
  },
];

export default function TopScorers({ title }) {
  return (
    <>
      <StyledAnalyticsHeader>
        <Typography size="large" fontWeight={600}>
          {title}
        </Typography>
      </StyledAnalyticsHeader>
      <Stack direction="row">
        <StyledAnalyticsCard>
          {scorers.map((data, key) => (
            <Stack spacing={0.5} key={key}>
              <Stack justifyContent="center" alignItems="center">
                <Avatar
                  src={data.profile}
                  alt={data.name}
                  sx={{ width: 60, height: 60 }}
                />
              </Stack>
              <Stack>
                <Typography size="small" fontWeight={600}>
                  {data.name}
                </Typography>
                <Typography size="small" fontWeight={600}>
                  Total Coin(s) : {data.points}
                </Typography>
              </Stack>
              <StyledViewAllButton component={"a"} size="small">
                {data.button}
              </StyledViewAllButton>
            </Stack>
          ))}
        </StyledAnalyticsCard>
      </Stack>
    </>
  );
}
