import { Close } from "@mui/icons-material";
import { IconButton, Skeleton, Stack } from "@mui/material";
import { Modal, Typography } from 'core';
//import { rGetRefEngPoints } from 'services/gameApi';
//---------------------------------------------------------

export default function CongratsPostForm({
  theme,
  authUser,
  feedData,
  shareOn,
  //destSMKey,
  //name,
  socialMedia,
  isProfileDetailsLoading,
  showCongratsFeedForm,
  onCloseCongratsFeedForm,
  actionData
}) {
  let mediaType;
  switch (showCongratsFeedForm.platform) {
    case 'tw':
      mediaType = 'Twitter';
      break;
    case 'fb':
      mediaType = 'Facebook';
      break;
    case 'ig':
    case 'igb':
    case 'igp':
      mediaType = 'Instagram';
      break;
    case 'ln':
      mediaType = 'LinkedIn';
      break;
    default:
      break;
  }

  // const { social_media } = feedData;
  const aUsrName = `${authUser?.first_name}`;
  let mediaActivity = 'sharing';
  if (showCongratsFeedForm?.action) {
    switch (showCongratsFeedForm?.action.toLowerCase()) {
      case 'share':
      default:
        mediaActivity = 'sharing';
        break;
    }
  }
  //---------------------------------------------------------

  return (
    <Modal open={showCongratsFeedForm.isOpen} onClose={onCloseCongratsFeedForm}>
      <Stack spacing={2} height="20rem">
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h6" size="medium"></Typography>
          </Stack>
          <IconButton onClick={onCloseCongratsFeedForm}>
            <Close color="primary" />
          </IconButton>
        </Stack>
        <Stack spacing={2} alignItems="center" height="100%" justifyContent='center' marginTop='-40px !important'>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6" size="medium">
              Thank you for {mediaActivity} on {mediaType}
            </Typography>
            {/*socialMedia[shareOn]*/}
          </Stack>
          {isProfileDetailsLoading ? (
            <Skeleton variant="rectangular" width={100} height={15} />
          ) : (//{showCongratsFeedForm?.points?.points} points
            <Typography textAlign="center" size="xlarge">
              {actionData?.message}
            </Typography>
            // <Typography size="xlarge">
            //   {aUsrName || 'Hi'}, you just earned{' '}
            //   {showCongratsFeedForm?.pointsEarned} points
            // </Typography>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
}
