import { Box, Grid, Stack, styled } from "@mui/material";
import React from "react";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { Typography } from "core";
//---------------------------------------------------------

const StyledSubmitIdeaCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  padding: "1rem",
}));

const StyledPointsText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: theme.palette.secondary.dark,
  fontWeight: "bolder",
  textAlign: "center",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    flexBasis: "100%",
  },
}));
//---------------------------------------------------------

export default function SubmitIdea() {
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <StyledGrid item xs={6}>
        <StyledSubmitIdeaCard
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <TipsAndUpdatesOutlinedIcon
            sx={{
              color: (theme) => theme.palette.background.paper,
              height: 120,
              width: 120,
              opacity: "1",
            }}
          />
          <Stack justifyContent="center">
            <StyledPointsText>YOUR IDEA IS WORTH 100 COINS</StyledPointsText>
            <Box
              sx={{
                maxWidth: "300px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography size="small" paddingBottom="1rem" fontWeight="bolder">
                Submit a post to the admin and you could win up to 100 Coins!
              </Typography>
            </Box>
          </Stack>
        </StyledSubmitIdeaCard>
      </StyledGrid>
    </Grid>
  );
}
