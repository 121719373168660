import { Box } from "@mui/material";
import AdmRewardPointsForm from './AdmRewardPointsForm';
//---------------------------------------------------------

export default function AdmRewardPoints({ theme, title = 'Reward Coins' }) {
	/*const theme = useTheme();
	const content = (
		<section className=''>
			<h3>Reward Coins</h3>
			<main><p>Coming Soon..</p></main>
		</section>
	);*/
	//---------------------------------------------------------

	return (//<div>{ content }</div>);
		<>
			<Box className="">
				<h2 className='pgHd'>{title}</h2>
			</Box>
			<Box className="bgWh padL xtBdr2"
				sx={{ maxWidth: '98%' }}
			//sx={{maxWidth: '60%', display: 'flex', gap: '0.6rem',
			//flexDirection: {xs:'column',md:'row'},}}
			>
				<AdmRewardPointsForm />
			</Box>
		</>
	);
};