import { Button, Typography } from "core";
import React from "react";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { Box, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { HOME_ROUTES } from "config/routes.config";

const StyledSubmitIdeaCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  padding: "1rem",
}));

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey,
  boxShadow: "none",
  fontSize: "14px",
  maxWidth: "150px",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

const StyledPointsText = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  color: theme.palette.secondary.dark,
  fontWeight: "bolder",
  lineHeight: "40px",
}));
const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-evenly",
  gap: "2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default function SubmitIdeaCard() {
  return (
    <StyledSubmitIdeaCard
      direction="row"
      columnGap={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <TipsAndUpdatesOutlinedIcon
        sx={{
          color: (theme) => theme.palette.background.paper,
          height: 100,
          width: 100,
          opacity: "1",
        }}
      />
      <StyledStack alignItems="center">
        <Stack justifyContent="center">
          <Typography color_variant="white" sx={{ fontSize: "14px" }}>
            YOUR IDEA IS WORTH
          </Typography>
          <StyledPointsText>100 COINS</StyledPointsText>
        </Stack>

        <Box maxWidth="300px">
          <Typography size="small" color_variant="white" paddingBottom="1rem">
            Submit a post to the admin and you could win up to 100 Coins
          </Typography>
          <StyledViewAllButton
            size="small"
            component={Link}
            to={HOME_ROUTES.Submit.url}
          >
            Submit Now
          </StyledViewAllButton>
        </Box>
      </StyledStack>
    </StyledSubmitIdeaCard>
  );
}
