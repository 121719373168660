import { Avatar, Box, Stack, styled } from "@mui/material";
import { Button, Typography } from "core";
import React from "react";
import { Link } from "react-router-dom";

const StyledAnalyticsCard = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  flexGrow: "1",
  justifyContent: "center",
  backgroundColor: theme.palette.background.paper,
  padding: "1rem",
  gap: "3rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StyledAnalyticsHeader = styled(Stack)(({ theme, highlight }) => ({
  padding: "0.5rem",
  backgroundColor: "#A0E13B",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.secondary.dark,
  fontWeight: 600,
  border: "2px solid grey",
  boxShadow: "none",
  fontSize: "13px",
  width: 130,
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center",
  padding: "0",
  textTransform: "capitalize",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

const scorers = [
  {
    profile: "/assets/user/alex.avif",
    name: "Alex",
    points: 200,
  },
  {
    profile: "/assets/user/ed.avif",
    name: "Ed",
    points: 200,
  },
  {
    profile: "/assets/user/dahiana.avif",
    name: "Dahiana",
    points: 200,
  },
];

export default function BonusPoints({ title }) {
  return (
    <>
      <StyledAnalyticsHeader>
        <Typography size="large" fontWeight={600}>
          {title}
        </Typography>
      </StyledAnalyticsHeader>
      <Stack direction="row">
        <StyledAnalyticsCard  >
          {scorers.map((data, key) => (
            <Stack key={key}>
              <Stack justifyContent="center" alignItems="center">
                <Avatar
                  src={data.profile}
                  alt={data.name}
                  sx={{ width: 60, height: 60 }}
                />
              </Stack>
              <Typography size="small" fontWeight={600}>
                {data.name}
              </Typography>

              <StyledButton component={"a"} size="small">
                Coins {data.points}
              </StyledButton>
            </Stack>
          ))}
        </StyledAnalyticsCard>
      </Stack>
    </>
  );
}
