import { Grid, useTheme } from "@mui/material";
import { PageContainer } from "core";
import React, { useState } from "react";
import ImageAndSearch from "sections/faq/ImageAndSearch";
import FaqQus from "sections/faq/FaqQus";
import { NIU_HEALTH_PRIVAY_POLICY_URL } from "config/app.config";

const faqData = [
  {
    sectionTitle: "Registration and Login",
    qas: [
      {
        question: "How do I create an account?",
        answer: "You can create an account by clicking the 'Sign Up' button and filling out the registration form.",
      },
      {
        question: "I'm having trouble logging in, what should I do?",
        answer: "Ensure your credentials are correct. If you've forgotten your password, use the 'Forgot Password' feature.",
      },
      // ... more questions ...
    ],
  },
  {
    sectionTitle: "Social Network Connections",
    qas: [
      {
        question: "How do I link my social media accounts?",
        answer: "Go to your account settings and follow the instructions to connect your social media accounts.",
      },
      {
        question: "Can I unlink my social media account later?",
        answer: "Yes, you can manage your social network connections from your account settings.",
      },
      // ... more questions ...
    ],
  },
  {
    sectionTitle: "Engagement Related FAQs",
    qas: [
      {
        question: "How can I increase my engagement on the platform?",
        answer: "Regularly posting content, interacting with other users, and sharing your posts on social media can increase engagement.",
      },
      {
        question: "What types of content get the most engagement?",
        answer: "Content that is informative, entertaining, and relevant to your audience tends to get more engagement.",
      },
      // ... more questions ...
    ],
  },
  {
    sectionTitle: "Coins Related FAQs",
    qas: [
      {
        question: "How do I earn Coins?",
        answer: "Coins can be earned by engaging with the platform, such as posting content, commenting, and sharing.",
      },
      {
        question: "Can I use Coins for rewards?",
        answer: "Yes, Coins can be exchanged for various rewards. Check the rewards section for more details.",
      },
      // ... more questions ...
    ],
  },
  {
    sectionTitle: "Other FAQs",
    qas: [
      {
        question: "How do I delete my account?",
        answer: 'For the latest details on the account deletion please see the <a href="' + NIU_HEALTH_PRIVAY_POLICY_URL + '" target="_blank">privacy policy.</a>',
      },
      {
        question: "Where can I find the terms and conditions?",
        answer: "The terms and conditions are available at the bottom of our website.",
      },
      {
        question: "How do I contact customer support?",
        answer: "You can contact customer support through the 'Help' section on our website.",
      },
      // ... more questions ...
    ],
  },
  // ... more sections ...
];

export default function Faq({ title }) {
  const theme = useTheme();
  const [filteredFaqData, setFilteredFaqData] = useState(faqData);

  return (
    <PageContainer title={title}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <ImageAndSearch theme={theme} faqData={faqData} setFilteredFaqData={setFilteredFaqData} />
        </Grid>
        <Grid item xs={12}>
          <FaqQus faqData={filteredFaqData} />
        </Grid>
      </Grid>
    </PageContainer>
  );
}
