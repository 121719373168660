//import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Box, styled } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Typography } from 'core';
import { SA_APP_NAME, } from 'config/app.config';
import { socialMedia, sMediaIco } from 'config/ui.config';
import {
	compareJsonObj, //decodeSNectConnResp, fInitSMAuth,
	//fFetchSMediaAccData, fFetchSMediaAccProps
} from 'lib/appFx';
//import { fDelCookie, fGetCookie, parseQStr } from 'lib/netFx';
import { selectAuthUserCredentials } from 'services/auth/authSlice';
import {
	getSMediaMgr, //resetSMediaTokens, useGetSMediaAccountListQuery,
	//useLazyGetSMediaAccountListQuery,
} from 'services/sMediaApi';
//---------------------------------------------------------

const CloseIcon = styled(HighlightOffIcon)(({ theme }) => ({
	position: "absolute", fontSize: "2rem",
	margin: "0.5rem", right: "18px", cursor: "pointer",
	[theme.breakpoints.down("sm")]: {
		fontSize: "1.4rem", margin: "6px", right: "5px",
	},
}));
const StyledTextBoxMain = styled(Typography)(({ theme }) => ({
	border: '0px solid green', backgroundColor: "#B8E3E9",
	//top:'3rem', //width: "400px",
	//width: "75%", //height: "80px",
	display: "flex", padding: "6px",
	alignItems: "center", justifyContent: "center", textAlign: "center",
	fontWeight: 900, margin: "auto",
	//bottom: "20rem", //left: "7rem", //position: "absolute",
	flexDirection: "column", fontSize: "1rem",
	[theme.breakpoints.down("sm")]: {
		//bottom: "12.5rem", //left: "1rem", //width: "310px",
		fontSize: "0.75rem", height: "2rem",
	},
}));
const StyledStack = styled(Stack)(({ theme }) => ({
	border: '0px solid yellow', //padding: '30px',
	margin: '10px auto', columnGap: 47,
	//position: "absolute", //position: "relative",
	alignItems: "center", justifyContent: 'center',
	top: "8.7rem", //left: "6.63rem",//"2.63rem",
	[theme.breakpoints.down("sm")]: {
		top: "3.8rem", //left: "1.5rem",
		columnGap: 25,
	},
}));
const XSocialIconContainer = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	width: "65px", height: "65px", display: "flex",
	alignItems: "center", justifyContent: "center",
	padding: "7px 5px 5px", cursor: "pointer",
	border: `0px solid  ${theme.palette.error.main} `,
	borderRadius: "100%",
	textAlign: "center",
	[theme.breakpoints.down("sm")]: { width: "40px", height: "40px", },
}));
const SocialIconContainer = styled('div')(({ theme, active, arrow }) => ({
	display: 'flex', alignItems: 'center', justifyContent: 'center',
	border: '0px solid green', borderRadius: '4px',
	height: '100%', //width: '100%',
	boxShadow: theme.shadows[5],
	backgroundColor: 'rgba(0,0,0,0)',
	textAlign: 'center', cursor: arrow,
}));
const StyledTextBoxContent = styled(Typography)(({ theme }) => ({
	backgroundColor: "#86CADD",
	height: "80px", width: "100%", //width: "370px",
	flexDirection: "column",
	display: "flex", alignItems: "center", justifyContent: "center",
	//padding: "px",
	textAlign: "center", fontWeight: 600,
	//position: "absolute", //margin: "auto",
	//bottom: "6rem", //left: "8rem",
	[theme.breakpoints.down("sm")]: {
		bottom: "4rem", //left: "1rem",
		width: "92%", //width: "310px",
		height: "35px", fontSize: "12px",
	},
}));
//const StyledTextBox = styled(Typography)(({ theme }) => ({
const StyledTextBox = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.primary.lighter,
	//position: 'relative', //width: "370px",
	width: "100%", height: "80px",
	display: "flex", alignItems: "center", justifyContent: "center",
	padding: "6px", textAlign: "center",
	fontWeight: 600, //position: "absolute",
	margin: "auto", //bottom: "-9rem", //left: "8rem",
	flexDirection: "column",
	[theme.breakpoints.down("sm")]: {
		bottom: "0.5rem", //left: "3rem", //width: "280px"
		width: "100%", height: "45px",
	},
}));
//const StyledTypography = styled(Typography)(({ theme }) => ({
const StyledTypography = styled('div')(({ theme }) => ({
	fontSize: "0.9rem",
	[theme.breakpoints.down("sm")]: { fontSize: "12px", },
}));

const social = [
	//{sMKey: 'fb', icon: socialMedia["facebook"]},
	{ sMKey: 'fb', icon: sMediaIco['fb'] },
	{ sMKey: 'tw', icon: sMediaIco['tw'] },
	//{sMKey: 'yt', icon: sMediaIco['yt']},
	{ sMKey: 'ln', icon: sMediaIco['ln'] },
	{ sMKey: 'ig', icon: sMediaIco['ig'] },
];//console.log('AAA', social);
const sMediaCIco = {
	'fb': { sMKey: 'fb' },
	'tw': { sMKey: 'tw' },
	//'yt': {sMKey: 'yt'},
	'ln': { sMKey: 'ln' },
	'ig': { sMKey: 'ig' },
};
//---------------------------------------------------------

export default function SocialNetworkCard3({
	sMediaMethods,
	onClose, uFeedFilter, setUFeedFilter, theme, //setOpen, setShownModal
}) {
	let theSMData, theSMList;
	const { authToken, isAdmin } = useSelector(selectAuthUserCredentials);
	//const fIniSMAuth = fInitSMAuth;
	theSMData = useSelector(getSMediaMgr);
	//console.log('theSMDataModal', theSMData);

	const setUFSMFilterStatus = (sKey, uFeedFilter, fCB, status = true) => {
		//if(typeof fCB === 'function')
		const currUFeedFilterStat = JSON.parse(JSON.stringify(uFeedFilter));
		Object.keys(currUFeedFilterStat).forEach((sK) => {
			if (sK === sKey) { currUFeedFilterStat[sK]['status'] = status; }
		}); console.log('uFeedFilterStat22_' + typeof (fCB), currUFeedFilterStat);
		//setUFeedFilter && setUFeedFilter(currUFeedFilterStat);
		if (!compareJsonObj(uFeedFilter, currUFeedFilterStat)) {
			fCB && fCB(currUFeedFilterStat);
		}
	};

	const fCheckSMConn = (e) => {
		e.preventDefault();
		let sMKey = e.target.getAttribute('data-smkey');
		if (!sMKey) { sMKey = e.currentTarget.getAttribute('data-smkey'); }
		if (!sMKey) { sMKey = e.target.parentElement.getAttribute('data-smkey'); }
		if (sMKey && theSMData && theSMData[sMKey] && !theSMData[sMKey]['activTokenId']) {
			const smConnEl = document.getElementById('lnkWidSMConn_' + sMKey);
			smConnEl && smConnEl.click();
			setTimeout(() => {
				let sMCheckCount = 0;
				const sMChkInterval = setInterval(() => {
					setUFSMFilterStatus(sMKey, uFeedFilter, setUFeedFilter)
					if (sMCheckCount++ > 7) { clearInterval(sMChkInterval); }
				}, 3000);
			}, 4000);
		}
		setTimeout(() => { onClose(); }, 5000);
	};
	const fAuthSMModal = (e, sKey) => {
		sMediaMethods.fInitAuthSM(e, sKey, true);
		//sMediaMethods.fInitAuthSM(e, sKey, setUFSMFilterStatus, [uFeedFilter, setUFeedFilter]);
		setTimeout(() => { onClose(); }, 7000);
	};

	if (0) {
		theSMList = [];
		Object.keys(sMediaCIco).forEach((sM) => {
			theSMList.push(
				<SocialIconContainer
					key={'sMConnMod_' + sM}
					data-smkey={sM}
					onClick={fCheckSMConn}
				>
					{sMediaIco(sM, fCheckSMConn)}
				</SocialIconContainer>)
		});
		theSMList = (<StyledStack direction="row">{theSMList}</StyledStack>);
	} else {
		if (theSMData) {
			theSMList = [];
			//const cStyle = {fontSize: '1rem'};

			for (let sK in theSMData) {
				let dR = theSMData[sK];
				const icon = `${sK}New`;
				let allowConn = dR.hasOwnProperty('authUrl') && sK !== 'yt';
				if (allowConn && ['ig', 'igb'].includes(sK)) {
					if (isAdmin) { allowConn = sK === 'igb' }
					else { allowConn = sK === 'ig' }
				}

				if (allowConn) {
					let lnkStat = dR?.connStat === 'Connected' ? true : false;
					//let status = lnkStat ? 'Disconnect' : 'Connect';
					//let disConnected = lnkStat ? false : true;
					let sMUserName = dR?.sMTokens?.name?.username;
					//let expired = lnkStat ? 'Expired in 21 days' : '';
					const allowDisconnect = false;
					let btnCursor;
					if (!allowDisconnect) { btnCursor = 'not-allowed' }
					//let arrow = lnkStat && 'pointer';
					let arrow = lnkStat && 'pointer';

					theSMList.push(
						<Grid item key={dR.sMId + '_' + dR.sMKey}
							alignItems="center" justifyContent="center"
							//sm={4} //xs={4} //sx={{height: '100%', }}
							style={{ margin: '8px', border: '0px solid red', }}//width: '45%'
							width={{ md: '45%', sm: '45%', xs: '90%', }}
						>
							<SocialIconContainer
								key={'sMConnMod_' + sK} data-smkey={sK}
								active={lnkStat} arrow={arrow}
								onClick={lnkStat ? null : (e) => {
									//fAuthSM(e, dR.sMKey, setUFSMFilterStatus, [uFeedFilter, setUFeedFilter])
									fAuthSMModal(e, dR.sMKey)
								}}
							//disabled={}
							>
								{socialMedia.NewIcons(icon, lnkStat, sMUserName, allowDisconnect)}
							</SocialIconContainer>
						</Grid>
					);
				}
			}

			theSMList = (
				<Grid container
					border="0px solid yellow"
					alignItems="center" justifyContent="center"
					spacing={'0.5'} padding='5px'
					columns={2} width={'60%'}
					rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }}
				>
					{theSMList}
				</Grid>
			);
		}
	}
	//---------------------------------------------------------

	return (//() => window.open(sM.link, "_blank")
		<Stack
			direction="column" spacing={0}
			justifyContent="center" alignItems="stretch"
			sx={{//height: '75vh',//'450px'
				backgroundColor: '#B8E3E9',
			}}
			height={{ md: '75vh', sm: '70%', xs: '70%', }}
		>
			<div onClick={onClose} style={{ border: '0px solid red' }}>
				<CloseIcon onClose={onClose} />
			</div>

			<Stack
				sx={{
					padding: '0px', height: '100%',
					border: '0px solid blue',
					//backgroundImage: "url('/assets/images/social.png')",
				}}
			>
				<Stack
					sx={{ border: '0px dashed green	', height: '10%' }}
				>&nbsp;</Stack>
				<Stack alignItems="center" justify="center">
					<StyledTextBoxMain>
						IT TAKES BOTH SIDES TO BUILD A BRIDGE!
					</StyledTextBoxMain>
				</Stack>
				<Stack
					justifyContent="center" alignItems="stretch"
					sx={{ border: '0px solid red', height: '100%', maxHeight: '33rem' }}
				>
					{theSMList}
				</Stack>
				<Stack
					alignItems="center" justify="center"
					sx={{ bottom: '0', backgroundColor: '#86CADD', }}
				>
					<StyledTextBoxContent>
						Connect your social networks NOW and get empowered<br /> to share more and
						earn more coins!
					</StyledTextBoxContent>
				</Stack>
			</Stack>

			<Stack
				alignItems="stretch" justifyContent="center"
				sx={{ border: '0px solid yellow' }}
			>
				<StyledTextBox>
					<StyledTypography fontWeight={900}>
						P.S. Your information is absolutely safe.
					</StyledTypography>
					<Typography fontSize="13px" fontWeight={600}>
						{SA_APP_NAME} will never post anything on your behalf!
					</Typography>
				</StyledTextBox>
			</Stack>
		</Stack>
	);
}