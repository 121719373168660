import { Box, IconButton, Stack } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import React from "react";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import { Typography } from "core";
import { Close } from "@mui/icons-material";

const StyledInputFieldContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  position: "absolute",
  left: "1.1rem",
  bottom: "5rem",
  [theme.breakpoints.down("sm")]: {
    left: "10px",
    bottom: "2rem",
  },
}));

const InputField = styled("input")(({ theme }) => ({
  padding: "0.5rem",
  width: "14.7rem",
  outlineColor: grey[400],
  border: `1px solid ${grey[400]}`,
  "&::placeholder": {
    fontWeight: 600,
    fontStyle: "italic",
  },
  [theme.breakpoints.down("sm")]: {
    width: "8rem",
  },
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  fontSize: "1.2rem",
  fontWeight: 600,
  left: "5rem",
  top: "1rem",
  background: "#b8e3e9",
  padding: "5px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "11px",
    left: "3rem",
    top: "0.5rem",
  },
}));
const StyledSubtitle = styled(Typography)(({ theme, top }) => ({
  position: "absolute",
  fontSize: "1rem",
  fontWeight: 600,
  left: "1rem",
  top: top ? "7.5rem" : "6rem",
  background: "#b8e3e9",
  padding: "3px",
  [theme.breakpoints.down("sm")]: {
    top: top ? "4.5rem" : "3.5rem",
    fontSize: "9px",
  },
}));
const IconContainer = styled(Box)(({ theme, top }) => ({
  position: "absolute",
  fontSize: "2rem",
  border: "2px solid #000",
  borderRadius: "100%",
  right: "9px",
  top: "0.5rem",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    right: "5px",
    top: "0.22rem",
    border: "1px solid #000",
  },
}));
const CloseIcon = styled(Close)(({ theme, top }) => ({
  fontSize: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export default function ReferralCard({ onClose, theme }) {
  return (
    <Stack position="relative">
      <img src="/assets/images/referal.png" alt="referral" />
      <StyledTitle>TEAMWORK MAKES THE DREAM WORK!</StyledTitle>
      <StyledSubtitle>Refer a friend and grow the tribe!</StyledSubtitle>
      <StyledSubtitle top>Each referral wins you 10 Coins!</StyledSubtitle>
      <StyledInputFieldContainer>
        <InputField
          name="email"
          type="email"
          color="success"
          placeholder="Add email address"
          fullWidth
          InputProps={{
            style: {
              backgroundColor: theme.palette.background.paper,
              minWidth: "25vw",
            },
          }}
        />
        <Box sx={{ background: "#99C134" }}>
          <IconButton sx={{ padding: "3px" }}>
            <ControlPointIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </StyledInputFieldContainer>

      <IconContainer onClick={onClose}>
        <CloseIcon />
      </IconContainer>
    </Stack>
  );
}
